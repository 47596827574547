export default class Consignee {
    ID!: string;
    fullname!: string;
    address!: string;
    email!: string;
    cellphone!: string;

    constructor(partial?: Partial<Consignee>) {
        Object.assign(this, partial);
    }
}
