<template>
  <div :class="{'nav-open': $sidebar.showSidebar}">
    <notifications />
    <router-view name="header"></router-view>
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
    <router-view name="footer"></router-view>
  </div>
</template>

<script>
import Session from "./Session";
import { RuntimeEventSubscriber } from "./util/Helper";

export default {
  components: {

  },
  data() {
    return {
      modalOrdine: {
        visible: false,
        order: Object
      }
    };
  },
  methods: {

  }
};
</script>
<style lang="scss">
</style>
