import { Type } from "class-transformer";
import ProductCategory from "../ProductCategory/ProductCategory";
import RequestManager from "../RequestManager";

export default class ProductModel extends RequestManager {
    model!: string;
    description!: string;
    @Type(() => ProductCategory)
    productCategory!: ProductCategory;

    get totalName(): string {
        return `${this.description} - ${this.model}`;
    }

    static getModels(query?: string, category?: string): Promise<ProductModel[]> {
        return ProductModel.getMany('productmodels', { params: { query: query, category: category } });
    }
}
