import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { SlideYUpTransition } from 'vue2-transitions';
import { ElForm } from 'element-ui/types/form';
import _ from 'lodash';
import Client, { Area } from '@/entities/Client/Client';

@Component({ name: 'EditClient', components: { SlideYUpTransition } })
export default class EditClient extends Vue {
    private show = false;

    private formRef: ElForm;

    private isDeleting = false;
    private hadOpenClass = false;
    private isEdit = false;
    private client = new Client();
    private form = {
        firstname: '',
        lastname: '',
        area: null,
        address: '',
        cellphone: '',
        email: ''
    };
    private oldForm = {
        firstname: '',
        lastname: '',
        area: null,
        address: '',
        cellphone: '',
        email: ''
    };

    private get clientName() {
        if (this?.client?.firstname !== undefined) return this.client.fullname;
        else return '';
    }

    private get formChanged() {
        return !_.isMatch(this.form, this.oldForm);
    }

    private get areas() {
        return Object.keys(Area).filter(key => !isNaN(Number(Area[key]))).map(key => { return { label: key, value: Area[key] } });
    }

    private edit() {
        this.isEdit = true;
    }

    private undo() {
        this.isEdit = false;

        this.initForm();
    }

    private saveChanges() {
        this.formRef.validate(async valid => {
            const loading = this.$loading({
                text: 'Caricamento in corso...',
                target: '#modalEditClientContent',
                customClass: 'roundLoadingView'
            });

            try {
                this.client.firstname = this.form.firstname;
                this.client.lastname = this.form.lastname;
                this.client.cellphone = this.form.cellphone;
                this.client.email = this.form.email;
                this.client.area = this.form.area;
                this.client.address = this.form.address;

                await this.client.update();

                this.$emit('onClientmodified', this.client.ID);

                this.$message.success({ message: 'Cliente modificato con successo', showClose: true });

                this.closeModal();
            } catch (error) {
                this.closeModal();

                this.$message.error({ message: 'Errore nella modifica del cliente', showClose: true });
            } finally {
                loading.close();
            }
        });
    }

    private async createPreventivo() {
        this.closeModal();
        await this.$router.push({ name: 'Nuovo preventivo', params: { clientID: this.client.ID } });
    }

    public async open(ID: string) {
        Object.assign(this, this.initData());

        const loading = this.$loading({
            text: 'Caricamento in corso...',
            target: '#modalEditClientContent',
            customClass: 'roundLoadingView'
        });

        try {
            this.show = true;

            this.client = await Client.getByID(ID);
            this.initForm();
        } catch (error) {
            this.closeModal();

            this.$message.error({ message: 'Errore nell\'apertura del cliente', showClose: true });
        } finally {
            loading.close();
        }
    }

    private initForm() {
        this.form.firstname = this.client.firstname;
        this.form.lastname = this.client.lastname;
        this.form.cellphone = this.client.cellphone;
        this.form.email = this.client.email;
        this.form.area = this.client.area;
        this.form.address = this.client.address;
        this.oldForm = _.cloneDeep(this.form);
    }

    private confirmClose() {
        if (this.formChanged) {
            this.$confirm('Ci sono modifiche non salvate, chiudere ugualmente?', 'Modifiche non salvate', {
                confirmButtonText: 'SÌ',
                cancelButtonText: 'Annulla',
                type: 'warning'
            })
                .then(() => {
                    this.closeModal();
                })
                .catch(() => {
                    return;
                });
        } else this.closeModal();
    }

    private closeModal() {
        this.show = false;
        this.$emit('close');
    }

    mounted() {
        this.formRef = <ElForm>this.$refs.form;
    }

    @Watch('show')
    private showChanged(val) {
        let documentClasses = document.body.classList;

        if (val) this.hadOpenClass = documentClasses.contains('modal-open');

        if (val && !this.hadOpenClass) {
            documentClasses.add('modal-open');
        } else if (!val && !this.hadOpenClass) {
            documentClasses.remove('modal-open');
        }
    }

    private initData() {
        return {
            isEdit: false,
            client: new Client(),
            form: {
                firstname: '',
                lastname: '',
                area: null,
                address: '',
                cellphone: '',
                email: ''
            },
            oldForm: {
                firstname: '',
                lastname: '',
                area: null,
                address: '',
                cellphone: '',
                email: ''
            }
        };
    }
}