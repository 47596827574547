<template>
  <div class="sidebar" :data-color="backgroundColor" :data-active-color="activeColor">
    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <slot></slot>
      <ul class="nav">
        <slot name="links">
          <sidebar-item v-for="(link, index) in computedSidebarLinks" :key="link.name + index" :link="link">
            <sidebar-item
              v-for="(subLink, index) in link.children"
              :key="subLink.name + index"
              :link="subLink"
            ></sidebar-item>
          </sidebar-item>
        </slot>
      </ul>
    </div>
  </div>
</template>
<script>
import "perfect-scrollbar/css/perfect-scrollbar.css";
import sidebarLinks from "../../../sidebarLinks";
import Session from "../../../Session";
import Helper from "../../../util/Helper";

export default {
  props: {
    title: {
      type: String,
      default: "Preventivatore Scale Italia",
      description: "Sidebar title",
    },
    backgroundColor: {
      type: String,
      default: "black",
      validator: (value) => {
        let acceptedValues = ["white", "brown", "black"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: "Sidebar background color (white|brown|black)",
    },
    activeColor: {
      type: String,
      default: "primary",
      validator: (value) => {
        let acceptedValues = ["primary", "info", "success", "warning", "danger"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: "Sidebar active text color (primary|info|success|warning|danger)",
    },
    logo: {
      type: String,
      default: "static/img/easyfito-logo.png",
      description: "Sidebar Logo",
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
      description: "Sidebar links. Can be also provided as children components (sidebar-item)",
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    provide() {
      return {
        autoClose: this.autoClose,
      };
    },
    computedSidebarLinks: () => {
      return sidebarLinks.filter((link) => {
        if (link.path == "/pipedrive")
          if (Session.instance.user.ID == "2c66f94d-f6de-4a4c-ab9d-dcc2ea9f4c22") return true;
          else return false;
        else return true;
      });
    },
  },
  methods: {
    async initScrollBarAsync() {
      let isWindows = navigator.platform.startsWith("Win");
      if (!isWindows) {
        return;
      }
      const PerfectScroll = await import("perfect-scrollbar");
      PerfectScroll.initialize(this.$refs.sidebarScrollArea);
    },
  },
  mounted() {
    this.initScrollBarAsync();
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>
<style>
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}
</style>
