import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { SlideYUpTransition } from 'vue2-transitions';
import { ElForm } from 'element-ui/types/form';
import _ from 'lodash';
import Client, { Area } from '@/entities/Client/Client';

@Component({ name: 'AddClient', components: { SlideYUpTransition } })
export default class AddClient extends Vue {
    private show = false;
    private hadOpenClass = false;
    private formRef: ElForm;

    private validateEmail(rule, value: string, callback: ((error?: Error) => void)) {
        this.setRequiredFields();

        if (value === '' && this.form.cellphone == '') {
            callback(new Error('Inserire l\'email o il numero di telefono'));
        }
        else if (value !== '' && !/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gm.test(value)) {
            callback(new Error('Inserire l\'email valida'));
        }
        else {
            callback();

            this.formRef.clearValidate('cellphone');
        }
    }

    private setRequiredFields() {
        if (this.form.cellphone != '') {
            (<any>this.formRef.rules).email[0].required = false;
            (<any>this.formRef.rules).cellphone[0].required = true;
        }
        else if (this.form.email != '') {
            (<any>this.formRef.rules).email[0].required = true;
            (<any>this.formRef.rules).cellphone[0].required = false;
        }
        else {
            (<any>this.formRef.rules).email[0].required = true;
            (<any>this.formRef.rules).cellphone[0].required = true;
        }
    }

    private validateCellphone(rule, value, callback) {
        this.setRequiredFields();

        if (value === '' && this.form.email === '') {
            callback(new Error('Inserire il numero di cellulare o l\'email'));
        }
        else if (value !== '' && !/^(\((00|\+)39\)|(00|\+)39|())?(3[2-9])\d{7,8}$/gm.test(value)) {
            callback(new Error('Inserire il numero di telefono valido'));
        }
        else {
            callback();

            this.formRef.clearValidate('email');
        }
    }

    private form = {
        firstname: '',
        lastname: '',
        area: null,
        address: '',
        cellphone: '',
        email: ''
    };

    private rules = {
        firstname: [{ required: true, message: 'Inserire il nome', trigger: 'blur' }],
        lastname: [{ required: true, message: 'Inserire il cognome', trigger: 'blur' }],
        area: [{ required: true, message: 'Selezionare l\'area', trigger: 'change' }],
        address: [{ required: true, message: 'Inserire l\'indirizzo', trigger: 'blur' }],
        email: [{ required: true, validator: this.validateEmail, trigger: 'blur' }],
        cellphone: [{ required: true, validator: this.validateCellphone, trigger: 'blur' }]
    };

    private get areas() {
        return Object.keys(Area).filter(key => !isNaN(Number(Area[key]))).map(key => { return { label: key, value: Area[key] } });
    }

    private get formChanged() {
        return !_.isMatch(this.form, this.initData().form);
    }

    public open() {
        Object.assign(this, this.initData());

        this.formRef.clearValidate();
        this.show = true;
    }

    private closeModal() {
        this.show = false;
        this.$emit('close');
    }

    private confirmClose() {
        if (this.formChanged) {
            this.$confirm('Ci sono modifiche non salvate, chiudere ugualmente?', 'Modifiche non salvate', {
                confirmButtonText: 'SÌ',
                cancelButtonText: 'Annulla',
                type: 'warning'
            })
                .then(() => {
                    this.closeModal();
                })
                .catch(() => {
                    return;
                });
        } else this.closeModal();
    }

    private submitForm() {
        this.formRef.validate(async valid => {
            if (valid) {
                try {
                    var loading = this.$loading({
                        text: 'Aggiunta cliente in corso...',
                        target: '#addClientModalContent',
                        customClass: 'roundLoadingView'
                    });

                    const newClient = new Client();
                    newClient.firstname = this.form.firstname;
                    newClient.lastname = this.form.lastname;
                    newClient.address = this.form.address;
                    newClient.cellphone = this.form.cellphone;
                    newClient.area = this.form.area;
                    newClient.email = this.form.email;

                    const clientID = await newClient.create();

                    this.$message.success({ message: 'Cliente aggiunto con successo', showClose: true });
                    this.$emit('onNewClient', clientID);

                    this.closeModal();
                } catch (error) {
                    this.$message.error({ message: 'Errore nella creazione del cliente', showClose: true });
                } finally {
                    loading.close();
                }
            } else {
                return false;
            }
        });
    }

    @Watch('show')
    private showChanged(val) {
        const documentClasses = document.body.classList;

        if (val) this.hadOpenClass = documentClasses.contains('modal-open');

        if (val && !this.hadOpenClass) {
            documentClasses.add('modal-open');
        } else if (!val && !this.hadOpenClass) {
            documentClasses.remove('modal-open');
        }
    }

    mounted() {
        this.formRef = <ElForm>this.$refs.form;
    }

    private initData() {
        return {
            form: {
                firstname: '',
                lastname: '',
                area: null,
                address: '',
                cellphone: '',
                email: ''
            }
        };
    }
}
