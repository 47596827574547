import { Type } from "class-transformer";
import Consignee from "../Consignee/Consignee";
import RequestManager from "../RequestManager";

export enum UnitMisure {
    PZ
}

export interface ProductQuantity {
    product: string;
    quantity?: number;
    unitPrice?: number;
}

export interface PreventivoProductModel {
    stair?: Stair;
    railing?: Railing;
    loft?: Loft;
}

interface Stair {
    ID: string;
    description: string;
}

interface Railing {
    ID: string;
    description: string;
}

interface Loft {
    ID: string;
    description: string;
}

export interface CustomProductPost {
    code: string;
    description: string;
    quantity?: number;
    unitMisure: UnitMisure;
    price: number;
    percentage?: number;
}

export default class PreventivoRequest extends RequestManager {
    client!: string;
    productModels!: PreventivoProductModel;
    customProducts!: CustomProductPost[];
    discountPercentage!: number;
    HPP?: number;
    alzata?: number;
    pedata?: number;
    mountingPeople!: number;
    mountingDay!: number;
    clientAsConsignee!: boolean;
    finalPrice!: number;
    notes?: string;
    hasInvoiceDiscount!: boolean;
    @Type(() => Consignee)
    consignee?: Consignee;

    async save(): Promise<{ ID: string, serialnumber: string }> {
        return (await this.postOne('preventivi'));
    }

    static async getPDF(ID: string, type: string, full: boolean): Promise<{ blob: Blob, filename: string }> {
        const res = await PreventivoRequest.getRaw(`preventivi/pdf/${ID}`, { params: { type: type, full: full }, responseType: 'blob' });

        return { blob: new Blob([res.data], { type: 'application/pdf' }), filename: res.headers['content-disposition'].replace('filename=', '') };
    }

    async update(ID: string): Promise<void> {
        await this.patch(`preventivi/${ID}`);
    }
}
