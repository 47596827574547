import RequestManager from './RequestManager';

export default class JWT extends RequestManager {
    isValid!: boolean;

    static async check(token: string): Promise<Boolean> {
        try {
            let result = await JWT.getOne(`checkJWT/${token}`);
            return result.isValid;
        } catch {
            return false;
        }
    }
}
