export default [
  {
    name: 'Nuovo preventivo',
    icon: 'nc-icon nc-layout-11',
    path: '/nuovo-preventivo'
  },
  {
    name: 'Clienti',
    icon: 'fas fa-address-book',
    path: '/clienti'
  },
  {
    name: 'Preventivi',
    icon: 'fas fa-euro-sign',
    path: '/preventivi'
  },
  {
    name: 'Statistiche Pipedrive',
    icon: 'fas fa-chart-line',
    path: '/pipedrive'
  },
]
