import RequestManager from '@/entities/RequestManager';

export default class PipedriveByUser extends RequestManager {
    public ID!: number;
    public name!: string;
    public sold!: string;
    public margin!: string;
    public closingRatio!: string;

    static getStats(startDate: Date, endDate: Date) {
        return PipedriveByUser.getMany("pipedrivebyuser", { params: { startDate: startDate, endDate: endDate } });
    }
}
