import RequestManager from '../RequestManager';
import { Type } from 'class-transformer';
import Session from '@/Session';

// export interface INewUser {
//     firstName: string;
//     lastName: string;
//     username?: string;
//     phone?: string;
// }

// class NewUser extends RequestManager implements INewUser {
//     firstName: string;
//     lastName: string;
//     username?: string;
//     email?: string;
// }

// export interface IUpdateUser {
//     firstName?: string;
//     lastName?: string;
//     disable?: boolean;
// }

// class UpdateUser extends RequestManager implements IUpdateUser {
//     firstName?: string;
//     lastName?: string;
//     color?: string;
//     disable?: boolean;
// }

export interface UserStats {
    preventiviCount: number,
    discountMean: number
}

export default class User extends RequestManager {
    ID!: string;
    firstname!: string;
    lastname!: string;
    email!: string;
    cellphone!: string;
    description?: string;
    userStats?: UserStats;

    get fullName(): string {
        return `${this.firstname} ${this.lastname}`;
    }

    public async getStats(): Promise<UserStats> {
        this.userStats = (await User.getRaw(`users/${this.ID}/stats`)).data;

        return this.userStats;
    }

    // get permission(): Permission {
    //     return this.userPermission.permission;
    // }

    // async restorePassword(): Promise<string> {
    //     return (await (new User()).postOne(`users/${Session.instance.user.ID}/restorePassword`)).password
    // }

    // static getUsers() {
    //     return User.getMany("users");
    // }

    // static setNewPassword(oldPassword: string, newPassword: string) {
    //     let newUser = new User();
    //     newUser.oldPassword = oldPassword;
    //     newUser.newPassword = newPassword;

    //     return newUser.postOne(`users/${Session.instance.user.ID}/changePassword`);
    // }

    // static async newUser(user: INewUser): Promise<string> {
    //     let newUser = new NewUser();
    //     newUser.firstName = user.firstName;
    //     newUser.lastName = user.lastName;
    //     newUser.username = user.username;
    //     newUser.color = user.color;
    //     newUser.email = user.email;
    //     newUser.phone = user.phone;
    //     newUser.permission = user.permission;

    //     return (await newUser.postOne("users")).password;
    // }

    // static async updateUser(ID: number, user: IUpdateUser) {
    //     let updateUser = new UpdateUser();
    //     updateUser.firstName = user.firstName;
    //     updateUser.lastName = user.lastName;
    //     updateUser.color = user.color;
    //     updateUser.permission = user.permission;

    //     return await updateUser.patch(`users/${ID}`);
    // }
}
