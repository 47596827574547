import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import Client from '@/entities/Client/Client';
import Product from '@/entities/Product/Product';
import ProductModel from '@/entities/ProductModel/ProductModel';
import PreventivoRequest, { PreventivoProductModel, UnitMisure } from '@/entities/Preventivo/PreventivoRequest';
import { ElTableColumn } from 'element-ui/types/table-column';
import { ElForm } from 'element-ui/types/form';
import Consignee from '@/entities/Consignee/Consignee';
import HelloUser from '../Modals/HelloUser/HelloUser';
import PreventivoResponse from '@/entities/Preventivo/PreventivoResponse';

interface SummaryParams {
    columns: Array<ElTableColumn>;
    data: Array<TableRow>;
}

interface TableRow {
    loading: boolean;
    rawProducts: Array<Product>;
    products: Array<any>;
    selectedProduct: string;
    description: string;
    quantity: number;
    um: string;
    price: string;
    percentage: string;
    isPercentage: boolean;
    unitPrice: number;
}

@Component({
    name: 'NuovoPreventivo',
    components: { HelloUser }
})
export default class NuovoPreventivo extends Vue {
    private saving = false;
    public clientsLoading = false;
    public preventivoLoading = false;
    private stairModelID = null;
    private stairDescription = '';
    private railingModelID = null;
    private railingDescription = '';
    private loftModelID = null;
    private loftDescription = '';
    private modelsLoading = false;
    private isClient = false;
    private isSaved = false;
    private rawClients = new Array<Client>();
    private rawModels = new Array<ProductModel>();
    private notes = '';
    private savedID = null;
    private pdfLoading = false;
    private fullPdfLoading = false;
    private pdfScaleItaliaLoading = false;
    private sameAddressDelivery = true;
    private tableSum = NaN;
    private pedataFromProject = false;
    private subtotale = '';
    private mountingPrice = '';
    private deliveryPrice = '';
    private importoNetto = '';
    private rilievoPrice = '';
    private isApFault = false;
    private serialnumber = '';
    private oldDiscountIndex = -1;
    private hasInvoiceDiscount = false;

    private newPreventivo = new PreventivoRequest();
    private rows = new Array<TableRow>();

    private stairsSettingsFormRef: ElForm;
    private pricesFormRef: ElForm;

    private validateFinalPrice(rule, value: string, callback) {
        if (value === '') {
            callback(new Error('Specificare il prezzo finale'));

            return;
        }

        if (!/^([0-9]+)(\,\d{1,2})?\€?$/.test(value)) {
            callback(new Error('Specificare un prezzo valido'));
        }
        else if (isLessThan250(this)) {
            callback(new Error('Non puoi arrotondare più di 250,00€'));
        }
        else {
            callback();
        }

        function isLessThan250(self: NuovoPreventivo): boolean {
            const finalPrice = parseFloat(value.replace(',', '.').replace('€', ''));
            const subtotale = parseFloat(self.subtotale.replace(',', '.').slice(0, -2));

            return (subtotale - finalPrice) > 250;
        }
    }

    private validateAlzata(rule, value: string, callback) {
        if (value === '') {
            this.isApFault = false;

            callback(new Error('Inserire l\'alzata'));

            return;
        }

        if (this.stairsSettingsForm.pedata == null || value == null) return;

        const alzata = parseFloat(value.replace(',', '.'));
        const pedata = parseFloat(this.stairsSettingsForm.pedata.replace(',', '.'));

        if (Number.isNaN(alzata)) {
            this.isApFault = false;

            callback(new Error('Inserire numero valido'));
        }
        else if (this.pedataFromProject) {
            this.isApFault = false;

            callback();
            this.stairsSettingsFormRef.clearValidate();
        }
        else if (Number.isNaN(pedata)) {
            this.stairsSettingsFormRef.validateField('pedata');

            callback(new Error('Pedata non valida'));
        }
        else {
            const ap = (2.0 * alzata) + pedata;

            if (ap >= 62 && ap <= 64) {
                callback();
                this.stairsSettingsFormRef.clearValidate();

                this.isApFault = false;
            }
            else {
                this.isApFault = true;

                callback(new Error('Rapporto 2A + P non valido'));
            }

        }
    }

    private validatePedata(rule, value: string, callback) {
        if (value === '' && !this.pedataFromProject) {
            this.isApFault = false;

            callback(new Error('Inserire la pedata'));

            return;
        }

        if (this.pedataFromProject) {
            this.isApFault = false;

            callback();
            this.stairsSettingsFormRef.clearValidate();
        }

        if (this.stairsSettingsForm.alzata == null || value == null) return;

        const alzata = parseFloat(this.stairsSettingsForm.alzata.replace(',', '.'));
        const pedata = parseFloat(value.replace(',', '.'));

        if (Number.isNaN(pedata)) {
            this.isApFault = false;

            callback(new Error('Inserire numero valido'));
        }
        else if (Number.isNaN(alzata)) {
            this.stairsSettingsFormRef.validateField('alzata');

            callback(new Error('Alzata non valida'));
        }
        else {
            const ap = (2.0 * alzata) + pedata;

            if (ap >= 62 && ap <= 64) {
                this.isApFault = false;

                callback();
                this.stairsSettingsFormRef.clearValidate();
            }
            else {
                this.isApFault = true;

                callback(new Error('Rapporto 2A + P non valido'));
            }
        }
    }

    private pedataFromProjectChanged() {
        this.stairsSettingsFormRef.validateField(['alzata', 'pedata']);
    }

    private validateEmail(rule, value, callback) {
        if (value === '')
            callback(new Error('Inserire un\'email'));
        else if (!/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gm.test(value)) {
            callback(new Error('Inserire un\'email valida'));
        }
        else
            callback();
    }

    private validateCellphone(rule, value, callback) {
        if (value === '')
            callback(new Error('Inserire un numero di cellulare'));
        else if (!/^(\((00|\+)39\)|(00|\+)39|())?(3[2-9])\d{7,8}$/gm.test(value)) {
            callback(new Error('Inserire un numero di telefono valido'));
        }
        else
            callback();
    }

    private clientForm = {
        clientID: ''
    }

    private deliveryForm = {
        fullname: '',
        address: '',
        email: '',
        cellphone: ''
    }

    private pricesForm = {
        discount: null,
        percentageDiscount: -1,
        mountingDay: 1,
        mountingPeople: 2,
        finalPrice: ''
    }

    private stairsSettingsForm = {
        HPP: '',
        alzata: '',
        pedata: ''
    }

    private clientFormRules = {
        clientID: [{ required: true, message: 'Selezionare un cliente', trigger: 'change' }]
    }

    private pricesFormRules = {
        discount: [{ required: true, message: 'Selezionare uno sconto', trigger: 'change' }],
        mountingDay: [{ required: true, message: 'Specificare i giorni di montaggio', trigger: 'blur' }, { type: 'number', min: 1, message: 'Durata minima un giorno', trigger: 'blur' }],
        mountingPeople: [{ required: true, message: 'Specificare il personale per il montaggio', trigger: 'blur' }, { type: 'number', min: 1, message: 'Almeno una persona', trigger: 'blur' }],
        finalPrice: [{ required: true, validator: this.validateFinalPrice, trigger: 'blur' }]
    }

    private stairsSettingsFormRules = {
        HPP: [{ required: true, message: 'Specificare l\'HPP', trigger: 'blur' }],
        alzata: [{ required: true, validator: this.validateAlzata, trigger: 'change' }],
        pedata: [{ required: true, validator: this.validatePedata, trigger: 'change' }]
    }

    private deliveryFormRules = {
        fullname: [{ required: true, message: 'Inserire nome e cognome', trigger: 'blur' }],
        address: [{ required: true, message: 'Inserire un indirizzo', trigger: 'blur' }],
        email: [{ required: true, validator: this.validateEmail, trigger: 'blur' }],
        cellphone: [{ required: true, validator: this.validateCellphone, trigger: 'blur' }]
    }

    private get isChanged() {
        return false;
    }

    private get tableData() {
        const result = this.rows;

        if (result) {
            if (result.length == 0)
                this.addRow();

            return result;
        }
        else
            return [];
    }

    private get clients() {
        return this.rawClients.map((client) => {
            return { value: client.ID, label: client.fullname };
        });
    }

    private get models() {
        return this.rawModels.map((model) => {
            return { value: model.model, label: model.model, description: model.description };
        });
    }

    private get discounts() {
        if (this.rows.length == 0 || this.tableSum == 0 || Number.isNaN(this.tableSum)) //|| this.rows.findIndex(row => { return row.selectedProduct != '' }) == -1 
            return [];

        const result = new Array<{ key: number, leftLabel: string, rightLabel: string }>(13);

        for (let i = 1; i < 41; i++) {
            const percentage = i / 100.0;
            const discount = this.tableSum - (percentage * this.tableSum);

            result[i - 1] = { key: i - 1, leftLabel: `${i.toString()} %`, rightLabel: `${Number.isNaN(discount) ? '0,00' : discount.forceDecimals(true)} €` };
        }

        return result;
    }

    private discountSelected(val: number) {
        if (this.oldDiscountIndex == val) return;
        this.oldDiscountIndex = val;

        this.pricesForm.percentageDiscount = +this.discounts[val].leftLabel.slice(0, -2);
    }

    private async loadClients(query: string) {
        if (query !== '') {
            this.clientsLoading = true;

            this.rawClients = await Client.getClients({ fullname: query });

            this.clientsLoading = false;
        } else {
            this.rawClients = [];
        }
    }

    private clearModels() {
        this.rawModels = [];
    }

    private async loadModels(query: string, category: string) {
        if (query !== '' && category !== '') {
            this.modelsLoading = true;

            this.rawModels = await ProductModel.getModels(query, category);

            this.modelsLoading = false;
        } else {
            this.rawModels = [];
        }
    }

    private addRow() {
        if (this.rows)
            this.rows.push({
                loading: false,
                rawProducts: [],
                products: [],
                selectedProduct: '',
                description: '',
                quantity: 1,
                um: '',
                price: '',
                percentage: '',
                isPercentage: false,
                unitPrice: 0
            });
    }

    private deleteRow(row: TableRow) {
        if (row.selectedProduct.nullIfEmpty() != null)
            this.rows.delete(row);
    }

    private async findProduct(query: string, callback, row: TableRow) {
        if (query !== '') {
            row.loading = true;

            row.rawProducts = await Product.getProducts(query);
            row.products = row.rawProducts.filter(product => { return this.tableData.findIndex(data => { return data.selectedProduct == product.code }) == -1; }).map((product) => {
                return { value: product.code, label: `${product.description}` }; //${product.code} - 
            });

            row.loading = false;
        } else {
            row.products = [];
        }

        callback(row.products);
    }

    private productSelected(selected: string, row: TableRow) {
        const product = row.rawProducts.find((product) => {
            return product.code == selected;
        });

        if (product) {
            row.selectedProduct = product.code;
            row.description = product.description;
            row.price = product.isPercentage ? '' : product.price.forceDecimals(true);
            row.percentage = product.percentage?.forceDecimals(true);
            row.isPercentage = product.isPercentage;
            row.um = product.unitMisureString;
        }
    }

    private productLostfocus(row: TableRow) {
        if (this.rows == null) return;

        if (this.rows.indexOf(row) == this.rows.length - 1 && row.selectedProduct.nullIfEmpty() != null)
            this.addRow();
    }

    private printRowTotal(row: TableRow) {
        const tot = this.calcRowTotal(row);

        if (Number.isNaN(tot))
            return '0,00';
        else
            return tot.forceDecimals(true);
    }

    private calcRowTotal(row: TableRow) {
        const price = row.isPercentage ? row.unitPrice : parseFloat(row.price.replace(',', '.'));

        if (Number.isNaN(price))
            return NaN;

        return price * (row.isPercentage ? (parseFloat(row.percentage.replace(',', '.')) / 100.0) : row.quantity);
    }

    private calcTotal(params) {
        const { columns, data }: SummaryParams = params;
        const result = [];

        columns.forEach((element, index) => {
            let sum = 0;

            data.forEach(element => {
                const partial = this.calcRowTotal(element);

                if (Number.isNaN(partial))
                    return;

                sum += partial;
            });

            this.tableSum = sum;
            result[5] = `Totale articoli ${sum.forceDecimals(true)} €`;
        });

        return result;
    }

    private async getPDF(type: string, full: boolean) {
        try {
            if (type == 'DuelleScale') {
                if (full) {
                    this.fullPdfLoading = true;
                }
                else {
                    this.pdfLoading = true;
                }
            }
            else {
                this.pdfScaleItaliaLoading = true;
            }

            const pdf = await PreventivoRequest.getPDF(this.savedID, type, full);

            const link = document.createElement('a');
            link.href = URL.createObjectURL(pdf.blob);
            link.download = pdf.filename;
            link.click();
            URL.revokeObjectURL(link.href);
            link.remove();

            if (type == 'DuelleScale') {
                if (full) {
                    this.fullPdfLoading = false;
                }
                else {
                    this.pdfLoading = false;
                }
            }
            else {
                this.pdfScaleItaliaLoading = false;
            }
        } catch (error) {
            if (type == 'DuelleScale') {
                if (full) {
                    this.fullPdfLoading = false;
                }
                else {
                    this.pdfLoading = false;
                }
            }
            else {
                this.pdfScaleItaliaLoading = false;
            }

            this.$message.error({ message: 'Errore nel download del PDF', showClose: true });
        }
    }

    private async save() {
        this.saving = true;

        let priceValid = false;
        let stairsValid = false;
        let deliverValid = false;
        let clientValid = false;

        try {
            priceValid = await this.pricesFormRef.validate();
        }
        catch { }

        try {
            clientValid = await (<ElForm>this.$refs.clientForm).validate();
        }
        catch { }

        try {
            if (!this.sameAddressDelivery)
                deliverValid = await (<ElForm>this.$refs.deliveryForm).validate();
            else
                deliverValid = true;
        }
        catch { }

        try {
            if (this.stairModelID != null)
                stairsValid = await this.stairsSettingsFormRef.validate();
            else
                stairsValid = true;
        }
        catch { }

        const isValid = priceValid && clientValid && deliverValid && (stairsValid || this.isApFault);

        if (isValid) {
            try {
                if (!this.isSaved) {
                    const response = await this.preparePreventivo().save();
                    this.savedID = response.ID;
                    this.serialnumber = response.serialnumber;

                    this.$message.success({ message: 'Preventivo salvato con successo', showClose: true });

                    this.isSaved = true;
                }
                else {
                    await this.preparePreventivo().update(this.savedID);

                    this.$message.success({ message: 'Preventivo aggiornato con successo', showClose: true });
                }
            } catch (error) {
                console.log(error);

                this.$message.error({ message: 'Errore nel salvare il preventivo', showClose: true });
            }
        }

        this.saving = false;
    }

    private preparePreventivo(): PreventivoRequest {
        const preventivo = new PreventivoRequest();

        preventivo.client = this.clientForm.clientID;

        preventivo.productModels = <PreventivoProductModel>{
            stair: this.stairModelID ? { ID: this.stairModelID, description: this.stairDescription.trim() } : undefined,
            railing: this.railingDescription ? { ID: this.railingModelID, description: this.railingDescription.trim() } : undefined,
            loft: this.loftModelID ? { ID: this.loftModelID, description: this.loftDescription.trim() } : undefined
        };

        preventivo.customProducts = this.tableData.filter(row => { return row.selectedProduct != '' }).map((row) => {
            const result = { code: row.selectedProduct, description: row.description.trim(), unitMisure: UnitMisure.PZ, price: row.isPercentage ? row.unitPrice : parseFloat(row.price.replace(',', '.')) }

            if (row.isPercentage)
                Object.defineProperty(result, 'percentage', { value: parseFloat(row.percentage.replace(',', '.')), enumerable: true });
            else
                Object.defineProperty(result, 'quantity', { value: +row.quantity, enumerable: true });

            return result;
        });

        if (this.stairModelID != null) {
            preventivo.HPP = +this.stairsSettingsForm.HPP.replace(',', '.');
            preventivo.alzata = +this.stairsSettingsForm.alzata.replace(',', '.');

            if (!this.pedataFromProject)
                preventivo.pedata = +this.stairsSettingsForm.pedata.replace(',', '.');
        }

        preventivo.mountingPeople = this.pricesForm.mountingPeople;
        preventivo.mountingDay = this.pricesForm.mountingDay;
        preventivo.discountPercentage = this.pricesForm.percentageDiscount;
        preventivo.finalPrice = parseFloat(this.pricesForm.finalPrice.replace(',', '.'));
        preventivo.notes = this.notes.trim().nullIfEmpty() ?? undefined;
        preventivo.clientAsConsignee = this.sameAddressDelivery;
        preventivo.hasInvoiceDiscount = this.hasInvoiceDiscount;

        if (!this.sameAddressDelivery)
            preventivo.consignee = new Consignee(this.deliveryForm);

        return preventivo;
    }

    private stairSelected(selectedID: string) {
        this.stairDescription = this.models.find(model => { return model.value == selectedID })?.description;
    }

    private railingSelected(selectedID: string) {
        this.railingDescription = this.models.find(model => { return model.value == selectedID })?.description;
    }

    private loftSelected(selectedID: string) {
        this.loftDescription = this.models.find(model => { return model.value == selectedID })?.description;
    }

    async mounted() {
        this.stairsSettingsFormRef = <ElForm>this.$refs.stairsSettingsForm;
        this.pricesFormRef = <ElForm>this.$refs.pricesForm;

        if (!Object.isEmpty(this.$route.params)) {
            this.preventivoLoading = true;

            if (this.$route.params?.clientID) {
                const client = await Client.getByID(this.$route.params.clientID);

                this.rawClients.push(client);
                this.clientForm.clientID = client.ID;
            }
            else if (this.$route.params?.preventivoID) {
                const preventivo = await PreventivoResponse.getByID(this.$route.params.preventivoID);

                this.isSaved = true;
                this.savedID = preventivo.ID;
                this.serialnumber = preventivo.serialnumber;

                this.notes = preventivo.notes ?? '';

                this.rawClients.push(preventivo.client);
                this.clientForm.clientID = preventivo.client.ID;

                this.rows = new Array<TableRow>();

                this.rows.push(...preventivo.customProducts.map(product => {
                    return <TableRow>{
                        loading: false, selectedProduct: product.code, description: product.description,
                        quantity: product.quantity, price: product.price?.toString(), percentage: product.percentage?.toString(),
                        rawProducts: new Array<Product>(), products: new Array<any>(), um: product.unitMisureString, unitPrice: product.price,
                        isPercentage: product.isPercentage
                    }
                }));
                this.addRow();

                for (let i = 0; i < 13; i++) {
                    const percentage = i + 25.0;

                    if (percentage == preventivo.discountProductsPercentage) {
                        this.pricesForm.discount = i;
                        this.pricesForm.percentageDiscount = preventivo.discountProductsPercentage;

                        break;
                    }
                }

                this.pricesForm.finalPrice = preventivo.finalPrice.toString();
                this.pricesForm.mountingDay = preventivo.mountingDay;
                this.pricesForm.mountingPeople = preventivo.mountingPeople;

                this.hasInvoiceDiscount = preventivo.hasInvoiceDiscount;

                this.deliveryForm.fullname = preventivo.consignee.fullname;
                this.deliveryForm.address = preventivo.consignee.address;
                this.deliveryForm.cellphone = preventivo.consignee.cellphone;
                this.deliveryForm.email = preventivo.consignee.email;

                this.sameAddressDelivery = preventivo.isClientAsConsegnee;

                preventivo.preventivoToProductModels.forEach(preventivoToProductModel => {
                    const code = preventivoToProductModel.productModel.productCategory.code;

                    if (code == 'Scala') {
                        this.stairModelID = preventivoToProductModel.productModel.model;
                        this.stairDescription = preventivoToProductModel.description;

                        this.pedataFromProject = preventivo.pedata == null;
                        this.stairsSettingsForm.HPP = preventivo.HPP?.toString() ?? '';
                        this.stairsSettingsForm.pedata = preventivo.pedata?.toString() ?? '';
                        this.stairsSettingsForm.alzata = preventivo.alzata?.toString() ?? '';
                    }
                    else if (code == 'Ringhiera') {
                        this.railingModelID = preventivoToProductModel.productModel.model;
                        this.railingDescription = preventivoToProductModel.description;
                    }
                    else if (code == 'Soppalco') {
                        this.loftModelID = preventivoToProductModel.productModel.model;
                        this.loftDescription = preventivoToProductModel.description;
                    }
                })
            }
            else if (this.$route.params?.showStats) {
                (<HelloUser>this.$refs.hellouser).open();
            }

            this.preventivoLoading = false;
        }
    }

    @Watch('tableSum')
    @Watch('pricesForm.percentageDiscount')
    @Watch('pricesForm.mountingDay')
    @Watch('pricesForm.mountingPeople')
    private calcImportNetto() {
        const discount = this.pricesForm.percentageDiscount == -1 ? this.tableSum : this.tableSum - (this.tableSum * (this.pricesForm.percentageDiscount / 100.0));
        const mountingPrice = this.pricesForm.mountingDay * this.pricesForm.mountingPeople * 450;
        const deliveryPrice = this.tableSum * 0.04;
        const rilievoPrice = discount > 5000.0 ? 300 : 200;
        const subtotale = discount + mountingPrice + deliveryPrice + rilievoPrice;

        this.importoNetto = `${discount.forceDecimals(true)} €`;
        this.mountingPrice = `${mountingPrice.forceDecimals(true)} €`;
        this.subtotale = `${(subtotale).forceDecimals(true)} €`;
        this.deliveryPrice = `${deliveryPrice.forceDecimals(true)} €`;
        this.rilievoPrice = `${rilievoPrice.forceDecimals(true)} €`;
    }
}
