import RequestManager from "../RequestManager";;

export enum UnitMisure {
    PZ,
    PERC
}

export default class Product extends RequestManager {
    code!: string;
    description!: string;
    unitMisure!: UnitMisure
    price?: number;
    percentage?: number;

    get isPercentage(): boolean {
        return this?.percentage != null;
    }

    get unitMisureString(): string {
        if (this.unitMisure == UnitMisure.PZ)
            return 'PZ';
        else
            return '%';
    }

    static getProducts(query: string): Promise<Product[]> {
        return Product.getMany('products', { params: { query: query } });
    }
}
