import RequestManager from '@/entities/RequestManager';

export default class Pipedrive extends RequestManager {
    public area!: string;
    public won!: string;
    public lost: string;
    public running: string;
    public sold: string;
    public provvRicevute: string;
    public provvInAttesa: string;
    public provvTot: string;
    public vinteNonValorizzate: string;
    public meanTicket: string;
    public margin: string;
    public closingRatio: string;

    static getStats(startDate: Date, endDate: Date) {
        return Pipedrive.getMany("pipedrive", { params: { startDate: startDate, endDate: endDate } });
    }
}
