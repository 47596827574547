import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Pipedrive from '@/entities/Pipedrive/Pipedrive';
import PipedriveByUser from '@/entities/Pipedrive/PipedriveByUser';
import VueApexCharts from 'vue-apexcharts';

@Component({ name: 'PipedriveView', components: { apexchart: VueApexCharts } })
export default class PipedriveView extends Vue {
    private rawData = new Array<Pipedrive>();
    private rawDataByUser = new Array<PipedriveByUser>();
    private pagination = { perPage: 10, currentPage: 1, perPageOptions: [10, 25, 50], total: 0 };
    private dateRange = null;

    private pickerOptions = {
        shortcuts: [
            {
                text: 'Settimana scorsa',
                onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', [start, end]);
                }
            },
            {
                text: 'Mese scorso',
                onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    picker.$emit('pick', [start, end]);
                }
            },
            {
                text: 'Ultimi 3 mesi',
                onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    picker.$emit('pick', [start, end]);
                }
            }
        ]
    };

    private firstMarginSeries = [];
    private salesSeries = [];
    private provvSeries = [];
    private runningSeries = [];
    private closingRatioSeries = [];
    private soldByUserSeries = [];
    private marginByUserSeries = [];
    private closingRatioByUserSeries = [];

    private marginChartOptions = {
        chart: {
            type: 'bar'
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%'
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: ['']
        },
        yaxis: {
            title: {
                text: '%'
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val + ' %';
                }
            }
        }
    };

    private salesChartOptions = {
        chart: {
            type: 'bar'
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%'
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: ['']
        },
        yaxis: {
            title: {
                text: '€'
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val + ' €';
                }
            }
        }
    };

    private provvChartOptions = {
        chart: {
            type: 'bar'
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%'
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: ['']
        },
        yaxis: {
            title: {
                text: '€'
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val + ' €';
                }
            }
        }
    };

    private runningChartOptions = {
        chart: {
            type: 'bar'
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%'
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: ['']
        },
        yaxis: {
            title: {
                text: '#'
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val;
                }
            }
        }
    };

    private closingRatioChartOptions = {
        chart: {
            type: 'bar'
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%'
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: ['']
        },
        yaxis: {
            title: {
                text: '%'
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val + ' %';
                }
            }
        }
    };

    private closingRatioByUserChartOptions = {
        chart: {
            type: 'bar'
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%'
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: ['']
        },
        yaxis: {
            title: {
                text: '%'
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val + ' %';
                }
            }
        }
    };

    private soldByUserChartOptions = {
        chart: {
            type: 'bar'
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%'
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: ['']
        },
        yaxis: {
            title: {
                text: '€'
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val + ' €';
                }
            }
        }
    };

    private marginByUserChartOptions = {
        chart: {
            type: 'bar'
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%'
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: ['']
        },
        yaxis: {
            title: {
                text: '%'
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val + ' %';
                }
            }
        }
    };

    private get tableData() {
        return this.rawData.map(data => {
            return {
                area: data.area,
                won: parseInt(data.won),
                lost: parseInt(data.lost),
                running: parseInt(data.running),
                sold: parseInt(data.sold),
                provvRicevute: parseInt(data.provvRicevute),
                provvInAttesa: parseInt(data.provvInAttesa),
                provvTot: parseInt(data.provvTot),
                vinteNonValorizzate: parseInt(data.vinteNonValorizzate),
                meanTicket: data.meanTicket == null ? 0 : parseInt(data.meanTicket),
                margin: data.margin == null ? 0 : parseFloat(data.margin).toFixed(1),
                closingRatio: data.closingRatio == null ? 0 : parseFloat(data.closingRatio).toFixed(1)
            };
        });
    }

    private get pagedData() {
        return this.tableData.slice(this.from, this.to);
    }

    private get to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
            highBound = this.total;
        }
        return highBound;
    }

    private get from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
    }

    private get total() {
        this.pagination.total = this.tableData.length;
        return this.tableData.length;
    }

    private async pickerChanged(val: Array<Date>) {
        if (!Object.isEmpty(val) && val?.length == 2) {
            this.rawData = await Pipedrive.getStats(val[0], val[1]);
            this.rawDataByUser = await PipedriveByUser.getStats(val[0], val[1]);

            this.firstMarginSeries = [];
            this.salesSeries = [];
            this.provvSeries = [];
            this.runningSeries = [];
            this.closingRatioSeries = [];
            this.soldByUserSeries = [];
            this.marginByUserSeries = [];
            this.closingRatioByUserSeries = [];

            this.tableData.forEach(x => {
                this.firstMarginSeries.push({ name: x.area, data: [x.margin] });
                this.salesSeries.push({ name: x.area, data: [x.sold] });
                this.provvSeries.push({ name: x.area, data: [x.provvInAttesa] });
                this.runningSeries.push({ name: x.area, data: [x.running] });
                this.closingRatioSeries.push({ name: x.area, data: [x.closingRatio] });
            });

            this.rawDataByUser.forEach(x => {
                this.soldByUserSeries.push({ name: x.name, data: [x.sold] });
                this.marginByUserSeries.push({ name: x.name, data: [x.margin] });
                this.closingRatioByUserSeries.push({ name: x.name, data: [x.closingRatio] });
            });
        }
    }
}