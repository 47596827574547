import PreventivoRequest from '@/entities/Preventivo/PreventivoRequest';
import PreventivoResponse from '@/entities/Preventivo/PreventivoResponse';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

interface TableRow {
    ID: string,
    serialnumber: string,
    clientFullname: string,
    finalprice: string,
    isLoadingPdf: boolean,
    isLoadingFullPdf: boolean
    isLoadingScaleItaliaPdf: boolean;
}

@Component({ name: 'PreventiviView', components: {} })
export default class PreventiviView extends Vue {
    private rawPreventivi = new Array<PreventivoResponse>();
    private pagination = { perPage: 10, currentPage: 1, perPageOptions: [10, 25, 50], total: 0 };
    private searchQuery = '';
    private searched = false;
    private searchResults = new Array<PreventivoResponse>();

    private get sales() {
        if (this.searched) return this.searchResults.map(this.mapPreventivo);
        return this.rawPreventivi.map(this.mapPreventivo);
    }

    private get pagedData() {
        return this.sales.slice(this.from, this.to);
    }

    private get to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
            highBound = this.total;
        }
        return highBound;
    }

    private get from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
    }

    private get total() {
        this.pagination.total = this.sales.length;
        return this.sales.length;
    }

    private mapPreventivo(preventivo: PreventivoResponse) {
        return { ID: preventivo.ID, serialnumber: preventivo.serialnumber, clientFullname: preventivo.client.fullname, finalprice: `${preventivo.finalPrice.forceDecimals(true)} €`, isLoadingPdf: false, isLoadingFullPdf: false, isLoadingScaleItaliaPdf: false };
    }

    private async searchPreventivi(query, callback: (data: Array<any>) => void) {
        const searchArray = await PreventivoResponse.get(query);
        const result = [];

        const map = new Map();
        for (const item of searchArray) {
            if (!map.has(item.ID)) {
                map.set(item.ID, true);
                result.push({
                    value: `${item.client.fullname} - ${item.serialnumber}`,
                    client: item
                });
            }
        }

        callback(result);
    }

    private async search(query: string | Object | KeyboardEvent) {
        if (typeof query == 'string') this.searchQuery = query;

        if (this.searchQuery == '' && this.searched) {
            this.searched = false;

            return;
        } else if (this.searchQuery == '' && !this.searched) return;

        if (query instanceof KeyboardEvent && query.key === 'Enter') {
            this.searchResults = await PreventivoResponse.get(this.searchQuery);

            this.searched = true;
        } else if (query instanceof Object && !(query instanceof KeyboardEvent)) {
            this.searchResults = await PreventivoResponse.get(this.searchQuery);

            this.searched = true;
        }
    }

    private newPreventivo() {
        this.$router.push({ name: 'Nuovo preventivo' });
    }

    private rowClick(row: TableRow) {
        this.$router.push({ name: 'Nuovo preventivo', params: { preventivoID: row.ID } });
    }

    private async createPDF(row: TableRow, type: string, full: boolean) {
        try {
            if (type == 'DuelleScale') {
                if (full) {
                    row.isLoadingFullPdf = true;
                }
                else {
                    row.isLoadingPdf = true;
                }
            }
            else {
                row.isLoadingScaleItaliaPdf = true;
            }

            const pdf = await PreventivoRequest.getPDF(row.ID, type, full);

            const link = document.createElement('a');
            link.href = URL.createObjectURL(pdf.blob);
            link.download = pdf.filename;
            link.click();
            URL.revokeObjectURL(link.href);
            link.remove();

            if (type == 'DuelleScale') {
                if (full) {
                    row.isLoadingFullPdf = false;
                }
                else {
                    row.isLoadingPdf = false;
                }
            }
            else {
                row.isLoadingScaleItaliaPdf = false;
            }
        } catch (error) {
            if (type == 'DuelleScale') {
                if (full) {
                    row.isLoadingFullPdf = false;
                }
                else {
                    row.isLoadingPdf = false;
                }
            }
            else {
                row.isLoadingScaleItaliaPdf = false;
            }

            this.$message.error({ message: 'Errore nel download del PDF', showClose: true });
        }
    }

    async mounted() {
        this.rawPreventivi = await PreventivoResponse.get();
    }
}
