import _ from 'lodash';

export default abstract class Helper {
    static prittifyDate(date: Date | string, trimHour = false): string {
        if (typeof date == "string") {
            date = date.toDate();
        }

        var options = {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit"
        };

        if (trimHour) {
            delete options.hour;
            delete options.minute;
        }

        let e = new Date(date);
        return String(e.toLocaleDateString("it-IT", options));
    }

    static prittifyTime(date: Date | string): string {
        if (typeof date == "string") {
            let time = date.split(":");

            date = new Date(1970, 1, 1, parseInt(time[0]), parseInt(time[1]))
        }

        var options = {
            hour: "2-digit",
            minute: "2-digit"
        };

        let e = new Date(date);
        return String(e.toLocaleTimeString("it-IT", options));
    }
}

export interface Listener {
    key: string;
    func: (arg?: any) => void;
}

export class RuntimeEventSubscriber {
    private static listeners = new Array<Listener>();

    static add(event: string, func: (arg?: any) => void) {
        this.listeners.push({ key: event, func: func });
    }

    static remove(event: string, func: (arg?: any) => void) {
        var callbackIndex = this.listeners.findIndex(item => item.key == event && item.func == func);
        if (callbackIndex > -1) this.listeners.splice(callbackIndex, 1);
    }

    static emit(event: string, arg?: any) {
        this.listeners.forEach(item => {
            if (item.key == event)
                item.func(arg);
        })
    }
}
