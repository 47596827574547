import 'reflect-metadata';
import "src/Extensions";
import Vue from 'vue';
import './pollyfills';
import VueRouter from 'vue-router';
import VueRouterPrefetch from 'vue-router-prefetch';
import VueNotify from 'vue-notifyjs';
import it from 'vee-validate/dist/locale/it';
import VeeValidate, { Validator } from 'vee-validate';
import locale from 'element-ui/lib/locale/lang/it';
import App from './App.vue';

// Plugins
import GlobalComponents from './globalComponents';
import GlobalDirectives from './globalDirectives';
import SideBar from './components/UIComponents/SidebarPlugin';
import progress from './progressbar';
import Element from 'element-ui'

// router setup
import routes, { IMetaRoute } from './routes';

// library imports

import './assets/sass/paper-dashboard.scss';

import sidebarLinks from './sidebarLinks';

import VueCookies from "vue-cookies-ts";
import Session from './Session';
import Helper from './util/Helper';

// plugin setup
Vue.use(Element, { locale });
Vue.use(VueRouter);
Vue.use(VueRouterPrefetch);
Vue.use(GlobalDirectives);
Vue.use(GlobalComponents);
Vue.use(VueNotify);
Vue.use(SideBar, { sidebarLinks: sidebarLinks });
Vue.use(VeeValidate, { fieldsBagName: 'formFields' });
Validator.localize('it', it);
Vue.use(VueCookies);

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'active',
  mode: 'history',
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash, behavior: 'smooth' }
    } else {
      return { x: 0, y: 0 }
    }
  }
});

progress.initProgress(router);

Session.instance.vueRouter = router;

router.beforeEach(async (to, from, next) => {
  let routeAuths: IMetaRoute = { requiresAuth: undefined };

  routeAuths.requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (routeAuths.requiresAuth) {
    if (await Session.instance.checkToken()) {
      next();
    }
    else {
      if (from.path !== '/login')
        next('/login');
      else
        stopNext(next);
    }
  }
  else if (await Session.instance.checkToken() && to.path === '/login') {
    stopNext(next);
    next(from.path);
  }
  else {
    next();
  }
});

function stopNext(next) {
  next(false);
  progress.stopProgress();
}

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router: router
});
