import { Type } from "class-transformer";
import Client from "../Client/Client";
import Consignee from "../Consignee/Consignee";
import RequestManager from "../RequestManager";
import { ProductQuantity, UnitMisure } from "./PreventivoRequest";

export default class PreventivoResponse extends RequestManager {
    ID!: string;
    @Type(() => Client)
    client!: Client;
    @Type(() => CustomProduct)
    customProducts?: CustomProduct[]
    preventivoToProductModels!: PreventivoToProductModel[];
    serialnumber!: string;
    HPP?: number;
    alzata?: number;
    pedata?: number;
    mountingPeople!: number;
    mountingDay!: number;
    discountProductsPercentage!: number;
    finalPrice!: number;
    @Type(() => Consignee)
    consignee!: Consignee;
    notes?: string;
    hasInvoiceDiscount!: boolean;

    get isClientAsConsegnee(): boolean {
        return this.consignee.address == this.client.address &&
            this.consignee.cellphone == this.client.cellphone &&
            this.consignee.fullname == this.client.fullname &&
            this.consignee.email == this.client.email;
    }

    static async get(): Promise<PreventivoResponse[]>;
    static async get(search: string): Promise<PreventivoResponse[]>;
    static async get(search?: string): Promise<PreventivoResponse[]> {
        if (search != null) {
            return PreventivoResponse.getMany('preventivi', { params: { search: search } });
        }

        return PreventivoResponse.getMany('preventivi');
    }

    static async getByID(ID: string): Promise<PreventivoResponse> {
        return PreventivoResponse.getOne(`preventivi/${ID}`);
    }
}

export class CustomProduct {
    ID: string;
    code: string;
    description: string;
    quantity?: number;
    unitMisure: UnitMisure
    price: number;
    percentage?: number;

    get unitMisureString(): string {
        if (this.unitMisure == UnitMisure.PZ)
            return 'PZ';
        else
            return '%';
    }

    get isPercentage(): boolean {
        return this?.percentage != null;
    }
}

export interface PreventivoToProductModel {
    ID: number;
    description: string;
    productModel: ProductModel;
}

export interface ProductModel {
    model: string;
    productCategory: ProductCategory;
    description: string;
}

export interface ProductCategory {
    code: string;
    description: string;
}
