<template>
  <navbar v-model="showNavbar">
    <div class="navbar-wrapper">
      <div class="navbar-minimize">
        <button id="minimizeSidebar" class="btn btn-icon btn-round" @click="minimizeSidebar">
          <i class="nc-icon nc-bullet-list-67 text-center visible-on-sidebar-mini"></i>
          <i class="nc-icon nc-align-center text-center visible-on-sidebar-regular"></i>
        </button>
      </div>
      <div class="navbar-toggle">
        <navbar-toggle-button @click.native="toggleSidebar" />
      </div>
      <p class="navbar-brand">Scale Italia</p>
    </div>

    <template slot="navbar-menu">
      <ul class="navbar-nav">
        <li class="nav-item">
          <el-button
            type="text"
            class="navbar-button"
            icon="fas fa-sign-out-alt"
            @click="logout"
          />
          <!-- <el-button
            type="text"
            class="navbar-button btn-rotate"
            icon="fas fa-cog"
            v-on:click="$router.push('/impostazioni')"
          /> -->
        </li>
      </ul>
    </template>
  </navbar>
</template>
<script>
import { Navbar, NavbarToggleButton } from "src/components/UIComponents";
import Session from "../../Session";

export default {
  components: {
    Navbar,
    NavbarToggleButton
  },
  data() {
    return {
      activeNotifications: false,
      showNavbar: false
    };
  },
  methods: {
    async logout() {
      try {
        await Session.instance.logout();

        this.$router.push("/login");
      } catch {}
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    minimizeSidebar() {
      this.$sidebar.toggleMinimize();
    },
    toggleNavbar() {
      this.showNavbar = !this.showNavbar;
    }
  }
};
</script>
<style scoped lang="scss">
.navbar-button {
  color: #2c2c2c;
  font-size: 18px;

  &:focus,
  &:hover {
    color: #409eff;
  }
}
</style>
