import _ from "lodash";

interface IDifferences {
    firstValue: any;
    secondValue: any;
}

declare global {
    interface ObjectConstructor {
        isEmpty(obj: Object): Boolean;
        byString(o: any, s: any): any;
        /**
        * Copy the values in common of the enumerable own properties from one or more source objects to a target object. If you want you can disable the typecheck.
        * @default typeCheck true
        */
        assignProperties(target: any, source: any, typeCheck?: boolean): void;
        differencesWith(first: any, second: any): Map<string, IDifferences>;
    }

    interface String {
        toDate(): Date;
        nullIfEmpty(): string | null;
    }

    interface Array<T> {
        delete(index: number): void;
        delete(item: T): void;
        delete(predicate: (value: T, index: number, obj: T[]) => unknown, thisArg?: any): void;
    }

    interface Date {
        /**
        * Will prittify the date in short and italian mode
        * @param hour if true will printout even the hours
        * @default hour true
        */
        prittify(hour?: boolean): string;
    }

    interface Number {
        /**
        * Will return the number with 2 decimals
        * @param replace if true will replace . with ,
        * @default replace false
        */
        forceDecimals(replace?: boolean): string;
    }
}

Object.byString = function (o, s) {
    s = s.replace(/\[(\w+)\]/g, '.$1');
    s = s.replace(/^\./, '');
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
}

Object.isEmpty = function (obj: any): Boolean {
    if (obj instanceof Date)
        return false;
    else if (obj === undefined || obj === null || obj === "")
        return true;
    else if ((typeof obj === "object" || Array.isArray(obj)))
        return Object.entries(obj).length === 0;
    else
        return false;
}

Object.assignProperties = function (target: any, source: any, typeCheck: boolean = true): void {
    for (const key in source) {
        if (key == 'constructor')
            continue;

        if (source[key]?.constructor !== undefined && target[key]?.constructor !== undefined)
            if (typeCheck && target[key].constructor === source[key].constructor)
                target[key] = source[key];
            else if (!typeCheck)
                target[key] = source[key];
    }
}

Object.differencesWith = function differencesWith(first: any, second: any): Map<string, IDifferences> {
    return _.reduce(
        first,
        (result, value, key) => {
            return _.isEqual(value, second[key]) ? result : result.set(key, { firstValue: value, secondValue: second[key] });
        },
        new Map<string, IDifferences>()
    );
}

String.prototype.toDate = function (): Date {
    const date = Date.parse(this);

    if (!isNaN(date)) {
        return new Date(date);
    }
    else
        throw Error("Invalid date");
}

String.prototype.nullIfEmpty = function (): string | null {
    if (this == '' || this == null)
        return null;
    else
        return this;
}

Array.prototype.delete = function <T>(predicate: ((value: T, index: number, obj: T[]) => unknown) | T | number, thisArg?: any): void {
    let index = -1;

    if (typeof predicate == "function")
        index = this.indexOf(this.find(predicate, thisArg));
    else if (predicate instanceof Object)
        index = this.indexOf(predicate);
    else if (typeof predicate == "number")
        index = predicate;

    if (index > -1)
        this.splice(index, 1);
}

Date.prototype.prittify = function (hour: boolean = true): string {
    const DD = ('0' + this.getDate()).slice(-2);
    const MM = ('0' + (this.getMonth() + 1)).slice(-2);
    const YYYY = this.getFullYear();

    const HH = ('0' + this.getHours()).slice(-2);
    const mm = ('0' + this.getMinutes()).slice(-2);

    return `${DD}/${MM}/${YYYY} ${hour == true ? `${HH}:${mm}` : ''}`
}

Number.prototype.forceDecimals = function (replace: boolean = false): string {
    let result = (Math.round(this * 100) / 100).toFixed(2);

    if (replace)
        result = result.replace('.', ',');

    return result;
}

export { }; 
