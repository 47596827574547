import User from './entities/User/User';
import axios from 'axios';
import Vue from 'vue';
import { plainToClass } from 'class-transformer';
import JWT from './entities/JWT';
import VueRouter from 'vue-router';

export default class Session {
    private static _instance: Session;
    public static get instance() {
        if (!this._instance)
            this._instance = new Session();

        return this._instance;
    };

    private _user = null;
    public get user(): User {
        if (this._user == null) {
            let userRaw = localStorage.getItem('user');

            if (userRaw != '')
                this._user = plainToClass(User, JSON.parse(userRaw));
        }

        return this._user;
    } public set user(value) {
        localStorage.setItem('user', JSON.stringify(value));
        this._user = value;
    }

    public vueRouter: VueRouter;

    private constructor() {
        axios.defaults.timeout = 30000;
        axios.defaults.headers.common.authorization = Vue.cookies.get('token');

        this.checkToken();
    }

    async login(email: string, password: string): Promise<User> {
        this.user = await User.post('users/login', { params: { email: email, password: password } });

        return this.user;
    }

    async logout() {
        await User.post(`users/${this.user.ID}/logout`);
        this.resetSession();
    }

    async checkToken(): Promise<Boolean> {
        if (!Vue.cookies.isKey('token'))
            return false;

        const token = <string>Vue.cookies.get('token');

        if (token == '') {
            this.resetSession();

            return false;
        }
        else {
            const result = await JWT.check(token.split(' ')[1]);

            if (!result)
                this.resetSession();

            return result;
        }
    }

    private resetSession() {
        axios.defaults.headers.common.authorization = null;
        this.user = null;
        localStorage.removeItem('user');
        Vue.cookies.remove('token');
    }
}
