import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Client, { Area } from '../../entities/Client/Client';
import AddClient from '@/views/Modals/AddClient/AddClient';
import EditClient from '../Modals/EditClient/EditClient';

interface TableRow {
    ID: string,
    fullName: string,
    cellphone: string,
    email: string,
    area: string
}

@Component({ name: 'ClientiView', components: { AddClient, EditClient } })
export default class ClientiView extends Vue {
    private rawClients = new Array<Client>();
    private searchResults = new Array<Client>();
    private pagination = { perPage: 10, currentPage: 1, perPageOptions: [10, 25, 50], total: 0 };
    private searchQuery = '';
    private searched = false;

    private modalAddClient: AddClient;
    private modalEditClient: EditClient;

    private get clients() {
        if (this.searched) return this.searchResults.map(this.mapClient);
        return this.rawClients.map(this.mapClient);
    }

    private get pagedData() {
        return this.clients.slice(this.from, this.to);
    }

    private get to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
            highBound = this.total;
        }
        return highBound;
    }

    private get from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
    }

    private get total() {
        this.pagination.total = this.clients.length;
        return this.clients.length;
    }

    private addClient() {
        this.modalAddClient.open();
    }

    private rowClick(row: TableRow) {
        this.modalEditClient.open(row.ID);
    }

    private async onNewClient(ID: string) {
        const client = await Client.getByID(ID);

        this.rawClients.push(client);
    }

    private async onClientmodified(ID: string) {
        const client = this.rawClients.find(client => { return client.ID == ID });

        if (client != undefined)
            Object.assign(client, await Client.getByID(ID));
    }

    private async searchClients(query, callback: (data: Array<any>) => void) {
        const searchArray = await Client.getClients({ fullname: query });
        const result = [];

        const map = new Map();
        for (const item of searchArray) {
            if (!map.has(item.fullname)) {
                map.set(item.fullname, true);
                result.push({
                    value: item.fullname,
                    client: item
                });
            }
        }

        callback(result);
    }

    private async search(query: string | Object | KeyboardEvent) {
        if (typeof query == 'string') this.searchQuery = query;

        if (this.searchQuery == '' && this.searched) {
            this.searched = false;

            return;
        } else if (this.searchQuery == '' && !this.searched) return;

        if (query instanceof KeyboardEvent && query.key === 'Enter') {
            this.searchResults = await Client.getClients({ fullname: this.searchQuery });

            this.searched = true;
        } else if (query instanceof Object && !(query instanceof KeyboardEvent)) {
            this.searchResults = await Client.getClients({ fullname: this.searchQuery });

            this.searched = true;
        }
    }

    private mapClient(client: Client) {
        return {
            ID: client.ID,
            fullName: client.fullname,
            cellphone: client.cellphone,
            email: client.email,
            area: Area[client.area]
        };
    }

    async mounted() {
        this.modalAddClient = <AddClient>this.$refs.addClient;
        this.modalEditClient = <EditClient>this.$refs.editClient;

        this.rawClients = await Client.getClients({ from: 1, to: 50 });
    }
}
