import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from "axios";
import { plainToClass } from "class-transformer";
import Vue from 'vue';

export interface IAPIError {
    httpCode: number;
    code: number;
    message: string;
}

export class APIError implements IAPIError {
    httpCode: number;
    code: number;
    message: string;

    static isAPIError(object: any) {
        return object instanceof APIError || instanceOfIAPIError(object);
    }
}

function isAxiosError<T>(error: AxiosError | any): error is AxiosError<T> {
    return error && error.isAxiosError
}

function instanceOfIAPIError(object: any): object is IAPIError {
    return 'code' in object && 'message' in object; //'httpCode' in object &&
}

export default abstract class RequestManager {
    private static readonly baseURL: string = 'https://api.gestionale.scaletrento.it/'; // 'http://localhost:4000/'; // 'https://api.demo-preventivatore.solutiontech.tech/';

    static async getMany<T>(this: (new () => T), url: string, config?: AxiosRequestConfig): Promise<T[]> {
        var obj = new Array<T>();

        axios.defaults.headers.common.user = RequestManager.getUser();
        let res = await axios.get(RequestManager.baseURL + url, config);

        res.data.forEach((d: T) => {
            obj.push(plainToClass(this, d));
        });

        return obj;
    }

    static async getRaw(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse> {
        axios.defaults.headers.common.user = RequestManager.getUser();

        return await axios.get(RequestManager.baseURL + url, config);
    }

    static async getOne<T>(this: (new () => T), url: string, config?: AxiosRequestConfig): Promise<T> {
        var obj: T;

        axios.defaults.headers.common.user = RequestManager.getUser();
        let res = await axios.get(RequestManager.baseURL + url, config);

        obj = plainToClass(this, res.data);

        return obj;
    }

    async postOne<T>(this: T, url: string, config?: AxiosRequestConfig): Promise<any> {
        axios.defaults.headers.common.user = RequestManager.getUser();

        return (await axios.post(RequestManager.baseURL + url, this, config)).data
    }

    async patch<T>(this: T, url: string, config?: AxiosRequestConfig): Promise<any> {
        axios.defaults.headers.common.user = RequestManager.getUser();

        return await (await axios.patch(RequestManager.baseURL + url, this, config)).data
    }

    static async post<T>(this: (new () => T), url: string, config?: AxiosRequestConfig): Promise<T> {
        try {
            var obj: T;

            axios.defaults.headers.common.user = RequestManager.getUser();
            let res = await axios.post(RequestManager.baseURL + url, this, config)

            if (url == "users/login")
                RequestManager.setToken(res.headers.authorization);


            obj = plainToClass(this, res.data);

            return obj;
        }
        catch (error) {
            if (isAxiosError(error) && error.response) {
                // console.log(error.response);
                if (APIError.isAPIError(error.response.data)) {
                    throw plainToClass(APIError, error.response.data);
                }
                else
                    throw error;
            }
            else
                throw error;
        }
    }

    static async delete<T>(this: (new () => T), url: string, config?: AxiosRequestConfig): Promise<any> {
        axios.defaults.headers.common.user = RequestManager.getUser();

        return await (await axios.delete(RequestManager.baseURL + url, config)).data
    }

    private static getUser(): string {
        let userRaw = JSON.parse(localStorage.getItem("user"));

        return JSON.stringify({ ID: userRaw?.ID });
    }

    private static setToken(token: string) {
        axios.defaults.headers.common.authorization = token;
        Vue.cookies.set("token", token, { secure: true });
    }
}
