import RequestManager from "../RequestManager";

export enum Area {
    Milano,
    Trento,
    Brescia,
    Verona,
    BZAltro
}

export interface SearchClient {
    fullname?: string;
    from?: number;
    to?: number;
}

export class UpdateClient extends RequestManager {
    firstname?: string;
    lastname?: string;
    cellphone?: string;
    email?: string;
    area?: number;
    address?: string;

    constructor();
    constructor(partial: Partial<UpdateClient>);
    constructor(partial?: Partial<UpdateClient>) {
        super();

        if (partial != null) {
            this.firstname = partial?.firstname;
            this.lastname = partial?.lastname;
            this.cellphone = partial?.cellphone;
            this.email = partial?.email;
            this.area = partial?.area;
            this.address = partial?.address;
        }
    }
}

export default class Client extends RequestManager {
    ID: string;
    firstname!: string;
    lastname!: string;
    cellphone!: string;
    email!: string;
    area!: number;
    address!: string;

    get fullname(): string {
        return `${this.firstname} ${this.lastname}`;
    }

    async update(): Promise<void> {
        const updateClient = new UpdateClient(this);

        await updateClient.patch(`clients/${this.ID}`);
    }

    async create(): Promise<string> {
        return (await this.postOne('clients')).ID;
    }

    static getClients(query?: SearchClient) {
        return Client.getMany('clients', { params: query });
    }

    static async getByID(ID: string): Promise<Client> {
        return Client.getOne(`clients/${ID}`);
    }
}
