import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Button } from '../../components/UIComponents';
import LoginInput from '../../components/UIComponents/Inputs/LoginInput.vue';
import AppFooter from '../../components/Layout/AppFooter.vue';
import Session from '../../Session';

@Component({
    components: {
        AppFooter,
        Button,
        'lg-input': LoginInput,
    },
})
export default class Login extends Vue {
    private isLoading = false;
    private isValidForm = false;
    private model = {
        email: '',
        password: '',
    };

    private modelValidations = {
        email: {
            required: true,
        },
        password: {
            required: true,
            min: 1,
        },
    };

    private async validate() {
        this.isValidForm = await this.$validator.validateAll();
    }

    private getError(fieldName) {
        return (<any>this).errors.first(fieldName);
    }

    private async login() {
        if (this.isValidForm) {
            try {
                this.isLoading = true;

                const user = await Session.instance.login(this.model.email, this.model.password);
                await user.getStats();

                await this.$router.push({ name: 'Nuovo preventivo', params: { showStats: 'true' } });
            } catch (error) {
                console.log(error);
                (<any>this).$notify({
                    title: 'Login fallito',
                    type: 'danger',
                    horizontalAlign: 'center',
                    verticalAlign: 'top',
                    timeout: 5000,
                    transitionMode: 'in-out',
                });
            }
            finally {
                this.isLoading = false;
            }
        }
    }
}