import Vue from 'vue'
import DashboardLayout from './components/Layout/DashboardLayout.vue'

// GeneralViews
import NotFound from './views/NotFoundPage.vue'

// Dashboard
import NuovoPreventivo from './views/NuovoPreventivo/NuovoPreventivo.vue'

// Clients view
import ClientiView from './views/Clienti/ClientiView.vue'

//Sells view
import PreventiviView from './views/Preventivi/PreventiviView.vue';

//Pipedrive view
import PipedriveView from './views/Pipedrive/PipedriveView.vue';

//Settings view
import SettingsView from './views/Settings/SettingsView.vue';

// Login
import Login from './views/Login/Login.vue'

export interface IMetaRoute {
  requiresAuth: boolean;
}

const loginPage = {
  path: '/login',
  name: 'Login',
  component: Login
}

const routes = [
  {
    path: '/',
    redirect: (to) => {
      if (Vue.cookies.isKey('token')) {
        return '/nuovo-preventivo'
      }
      return '/login'
    }
  },
  {
    path: '/',
    component: DashboardLayout,
    children: [
      {
        path: 'nuovo-preventivo',
        name: 'Nuovo preventivo',
        component: NuovoPreventivo,
        meta: <IMetaRoute>{ requiresAuth: true }
      },
      {
        path: 'clienti',
        name: 'Clienti',
        component: ClientiView,
        meta: <IMetaRoute>{ requiresAuth: true }
      },
      {
        path: 'preventivi',
        name: 'preventivi',
        component: PreventiviView,
        meta: <IMetaRoute>{ requiresAuth: true }
      },
      {
        path: 'pipedrive',
        Name: 'Statistiche Pipedrive',
        component: PipedriveView,
        meta: <IMetaRoute>{ requiresAuth: true }
      }
      // {
      //   path: 'impostazioni',
      //   name: 'Impostazioni',
      //   component: SettingsView,
      //   meta: <IMetaRoute>{ requiresAuth: true }
      // },
    ]
  },
  loginPage,
  { path: '*', component: NotFound }
]

export default routes
