import Session from "@/Session";
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { SlideYUpTransition } from "vue2-transitions";

@Component({ name: 'HelloUser', components: { SlideYUpTransition } })
export default class HelloUser extends Vue {
    private show = false;
    private user = Session.instance.user;

    private get preventiviCount(): number {
        if (this.user.userStats == null || this.user.userStats.preventiviCount == null)
            return 0;
        else
            return this.user.userStats.preventiviCount;
    }

    private get discountMean(): number {
        if (this.user.userStats == null || this.user.userStats.discountMean == null)
            return 0;
        else
            return this.user.userStats.discountMean;
    }

    private get discount(): string {
        if (this.user.userStats == null || this.user.userStats.discountMean == null)
            return '0,00';
        else
            return this.user.userStats.discountMean.forceDecimals(true);
    }

    public open() {
        this.show = true;
    }

    @Watch('show')
    private showChanged(val) {
        let documentClasses = document.body.classList;
        if (val) {
            documentClasses.add('modal-open');
        } else {
            documentClasses.remove('modal-open');
        }
    }
}
